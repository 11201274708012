<script lang="ts" setup>
const { t } = useT();
const isGuest = useIsGuest();
const { open } = useNlcModals();
const loginGuard = useLoginGuard();
const { depositStreakIsActive, prizeVaultIsActive, cosmicBoxesIsActive, seasonTowerIsActive } = useFeaturesIsActive();
const { isActiveMiniWheel } = useDepositStreakData({
	immediate: !isGuest.value && depositStreakIsActive.value
});
const isOpenPopperRewards = useState("isOpenPopperRewards", () => false);

defineEmits<{ (event: "toggleSidebar"): void }>();
defineProps<{ isShowBadgeActiveRaces: boolean }>();

const handleOpenCash = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "Buy",
		location: "navigation_bar"
	});
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
const handleTournamentClick = () => {
	loginGuard({
		success: () => {
			navigateTo("/tournaments");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handlePromoClick = () => {
	loginGuard({
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleWheelOfWinsClick = () => {
	isActiveMiniWheel.value ? open("LazyOModalWheelOfWinsPlayWheel") : navigateTo("/wheel-of-wins");
};
</script>

<template>
	<div :class="['bottom-menu-mobile', { 'open-popper': isOpenPopperRewards }]">
		<div class="navigation-item" @click="$emit('toggleSidebar')">
			<NuxtIcon class="icon-blue" name="20/burger" filled />
			<AText :size="10" :modifiers="['medium']" as="div">
				{{ t("Menu") }}
			</AText>
		</div>
		<NuxtLink class="navigation-item link" to="/issues/popular-games/">
			<NuxtIcon name="20/cherry" filled />
			<AText class="color-neutral-100" :size="10" :modifiers="['medium']" as="div">
				{{ t("Games") }}
			</AText>
		</NuxtLink>
		<div class="navigation-item">
			<div class="center">
				<AText
					v-if="isGuest"
					class="play-btn"
					:size="10"
					:modifiers="['uppercase', 'bold']"
					as="div"
					@click="open('LazyOModalSignup')"
				>
					{{ t("Play") }}
				</AText>
				<template v-else>
					<MSpookySeasonPumpkinProgress v-if="seasonTowerIsActive" class="spooky-season" />
					<MWheelOfWinsFakeWheel
						v-else-if="depositStreakIsActive"
						class="button-wheel-of-wins"
						clearSectors
						@click="handleWheelOfWinsClick()"
					/>
					<div v-else-if="prizeVaultIsActive" class="button-prize-vault" @click="open('LazyOModalPrizeVaultPlay')">
						<NuxtImg
							src="/nuxt-img/prize-vault/hiw-2.png"
							format="avif"
							width="84"
							height="84"
							loading="lazy"
							alt="prize vault"
						/>
					</div>
					<MCosmicBoxesButton v-else-if="cosmicBoxesIsActive" />
					<AText v-else class="play-btn" :size="10" :modifiers="['uppercase', 'bold']" as="div" @click="handleOpenCash">
						{{ t("Buy") }}
					</AText>
				</template>
			</div>
		</div>
		<div class="navigation-item" @click="handleTournamentClick()">
			<ABadge v-if="isShowBadgeActiveRaces" background="var(--custom-13)" :size="14" class="counter">
				<AText class="color-custom-9" :size="10" :modifiers="['bold']">1</AText>
			</ABadge>
			<NuxtIcon class="icon-blue" name="20/tournament" filled />
			<AText class="color-neutral-100" :size="10" :modifiers="['medium']" as="div">
				{{ t("Tournament") }}
			</AText>
		</div>
		<div class="navigation-item" @click="handlePromoClick()">
			<ORewards :class="{ 'hide-rewards': isGuest }">
				<NuxtIcon name="20/promo" filled />
				<AText class="color-neutral-100" :size="10" :modifiers="['medium']" as="div">{{ t("Promo") }}</AText>
			</ORewards>
		</div>
	</div>
</template>

<style scoped lang="scss">
.hide-rewards.popper-open :deep(.popper) {
	display: none !important;
}
.bottom-menu-mobile {
	@include media-breakpoint-up(lg) {
		display: none;
	}
	position: fixed;
	z-index: 2000;
	top: auto;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--custom-rgb-18);
	border-top: 1px solid var(--custom-rgb-6);
	border-radius: 10px 10px 0 0;
	backdrop-filter: blur(5px);
	padding: 8px 16px;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	&.open-popper {
		z-index: 2002;
	}
	.navigation-item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;
		padding: 8px 0;
		width: calc(100% / 5);
		:deep(.btn-rewards) {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 4px;
		}
	}
	.nuxt-icon {
		font-size: 20px;
		&.icon-blue:deep(svg) {
			path,
			rect {
				fill: var(--custom-3);
			}
		}
	}
}
.counter {
	position: absolute;
	top: calc(50% - 18px);
	right: calc(50% - 18px);
	border-radius: 50%;
}
.center {
	position: absolute;
	top: -16px;
	width: 54px;
	height: 54px;
	& > div {
		width: 100%;
		height: 100%;
	}
	.button-wheel-of-wins {
		width: 66px;
		height: 66px;
		left: -6px;
	}
	.spooky-season {
		transform: scale(1.2);
	}
	.button-prize-vault {
		margin-top: 14px;
		img {
			width: 100%;
			height: auto;
		}
	}
	.pumpkin-btn {
		width: 48px;
		height: 48px;
	}
}

.play-btn {
	border-radius: 50%;
	background: var(--additional-a-3);
	box-shadow: var(--additional-a-3) 0 0 12px 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
